import React from 'react';

const SMSPrivacyPolicy = () => {
  return (
    <div className="p-6 max-w-3xl mx-auto bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold text-center mb-4">Privacy Policy</h1>
      <p className="text-gray-600 text-center mb-6">Effective: February 17, 2025</p>

      <h2 className="text-xl font-semibold mt-4">About Us</h2>
      <p className="mb-4">
        “We”, “us” or “our” means Create.AI, Inc., with its principal place of business located at 301 Congress Ave 12th Floor Austin TX US 78701-2961.
      </p>

      <h2 className="text-xl font-semibold mt-4">About This Privacy Policy</h2>
      <p className="mb-4">
        Your privacy is important to us, so we’ve developed this Privacy Policy, which explains how we collect, use, and disclose your personal information. We collect personal information when you use our website(s), mobile apps, and other online and offline products, services and experiences (collectively, the “Services”). Please take a moment to read through this Policy in its entirety.
      </p>
      <p className="mb-4">
        If you have any questions, concerns or complaints regarding this Privacy Policy or how we use your personal information please contact us via e-mail at <a href="mailto:create@create.ai" className="text-blue-500">create@create.ai</a>.
      </p>

      <h2 className="text-xl font-semibold mt-4">What Personal Information We Collect and How We Collect It?</h2>
      <p className="mb-4">We collect personal information that you provide directly to us:</p>
      <ul className="list-disc list-inside mb-4">
        <li><strong>Contact information:</strong> If you sign up to receive our newsletter, emails, or text messages from us, we will collect your name, email address, mailing address, phone number, and any other information needed to contact you about the Services.</li>
        <li><strong>Payment information:</strong> To order products or services through the Services, you will need to provide us with payment information (like your bank account or credit card information). Please note that your financial information is collected and stored by a third party payment processing company. Use and storage of that information is governed by the third party payment processor’s applicable privacy policy.</li>
        <li><strong>Survey information:</strong> You may provide us with other personal information when you fill in a form, respond to our surveys or questionnaires, provide us with feedback, participate in promotions, or use other features of the Services.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">How We Use Your Personal Information?</h2>
      <p className="mb-4">We use the personal information we collect for the following reasons:</p>
      <ul className="list-disc list-inside mb-4">
        <li>To send you our newsletter, or other information or marketing about our Services that you think may be of interest to you.</li>
        <li>To reply to your questions, inquiries, or customer service requests or to send you notices, updates, security alerts, or support and administrative messages.</li>
        <li>To provide you with information about the Services that you request from us or which we feel may interest you.</li>
        <li>To monitor and analyze trends, usage and activities in connection with our Services and to improve the Services.</li>
        <li>To facilitate contests, sweepstakes and promotions, and to process entries and provide prizes and rewards.</li>
        <li>To detect, investigate and prevent fraudulent transactions and other illegal activities on the Services and to protect the rights and property of us and our customers.</li>
        <li>To carry out our obligations arising from any contracts entered into between you and us, including for billing and collection.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">How We Share Your Personal Information?</h2>
      <p className="mb-4">We may share your personal information in the following ways:</p>
      <ul className="list-disc list-inside mb-4">
        <li>With vendors, consultants, and other service providers who process your personal information on our behalf when they provide services to us, for example data analytics, research, marketing and financial services.</li>
        <li>In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company.</li>
      </ul>
      <p className="mb-4">
        We may be legally required to disclose or share your personal information without your consent in some circumstances, for example to comply with a court order or law enforcement. In such circumstances, we will only disclose your personal data if we have a good-faith belief that such sharing is required under applicable legal obligations.
      </p>

      <h2 className="text-xl font-semibold mt-4">Your Marketing Choices</h2>
      <p className="mb-4">When you sign up for a promotion like a sweepstakes, or subscribe to our newsletter or marketing/promotional messages, we use your personal information to help us decide which products, services and offers may be of interest to you.</p>
      <p className="mb-4">
        We will send marketing messages to you if you have asked us to send you information, bought goods or services from us, or if you provided us with your details when you entered a competition or registered for a promotion. If you opt out of receiving marketing messages, we may still send you non-promotional emails. We will ask for your consent before we share your personal information with any third party for their direct marketing purposes.
      </p>
      <p className="mb-4">
        You may unsubscribe from marketing messages through a link we include on messages we send you. You can also ask us to stop sending you marketing messages at any time by contacting us at: <a href="mailto:create@create.ai" className="text-blue-500">create@create.ai</a>.
      </p>

      <h2 className="text-xl font-semibold mt-4">Retention of Your Data and Deletion</h2>
      <p className="mb-4">Your personal information will not be kept longer than is necessary for the specific purpose for which it was collected.</p>
      <p className="mb-4">
        When we decide how long we will keep your information we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure, why we need it, and any relevant legal requirements (such as legal retention and destruction periods).
      </p>
      <p className="mb-4">
        The foregoing will, however, not prevent us from retaining any personal information if it is necessary to comply with our legal obligations, in order to file a legal claim or defend ourselves against a legal claim, or for evidential purposes.
      </p>

      <h2 className="text-xl font-semibold mt-4">International Transfers</h2>
      <p className="mb-4">We will ensure that any transfer of personal information to countries outside of the United States will take place pursuant to the appropriate safeguards.</p>

      <h2 className="text-xl font-semibold mt-4">Changes to This Privacy Policy</h2>
      <p className="mb-4">From time to time, we have the right to modify this Privacy Policy. We’re likely to update this Privacy Policy in the future and when we make changes. Please come back and check this page from time to time for the latest information on our privacy practices.</p>

      <h2 className="text-xl font-semibold mt-4">Contact Us</h2>
      <p className="mb-4">If you have questions or concerns about the information in this Privacy Policy, our handling of your personal information, or your choices and rights regarding such use, please do not hesitate to contact us at:</p>
      <address className="mb-4">
        Create.AI, Inc.<br />
        301 Congress Ave 12th Floor<br />
        Austin TX US 78701-2961<br />
        <a href="mailto:create@create.ai" className="text-blue-500">create@create.ai</a>
      </address>
    </div>
  );
};

export default SMSPrivacyPolicy;