import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import './createdotai.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';
import SMSPrivacyPolicy from './SMSPrivacyPolicy';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Helmet>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Create.AI - Amplify Your Imagination</title>
      <meta
        name="description"
        content="Create.AI is currently under development. Create.AI, Inc.'s generative AI platform helps you amplify your creativity & imagination."
      />
      <link rel="stylesheet" href="/assets/styles/output.css?&" />
      <link rel="icon" href="/assets/images/icon_white.png" type="image/png" />
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-DNTDL4R6LT"></script>
      <script>
      {`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'G-DNTDL4R6LT');`}
    </script>
    </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/sms-privacy-policy" element={<SMSPrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
