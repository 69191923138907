import './App.css';
import EmailList from './EmailList';

function App() {
  return (
    <div className="App overflow-hidden">
      <>
        <div className="absolute h-full w-full pointer-events-none"></div>
        <div className="absolute top-0 p-8 py-48 h-full w-full rounded-2xl overflow-clip -z-20">
          <div className="h-full w-full rounded-2xl ring-1 ring-inset ring-white/50 backdrop-blur-xl bg-white bg-opacity-30 shadow-xl mx-auto max-w-[950px]">
            <div className="flex items-center justify-center h-full w-full px-12">
              <div className="flex flex-col gap-4 lg:gap-6 relative">
                <a href="https://create.ai">
                  <img
                    className="flex-none mx-auto"
                    src="/assets/images/logo_full.png"
                    alt="Create.AI"
                    style={{
                      width: "100%",
                      maxWidth: 500,
                      filter: "drop-shadow(3px 3px 0px rgba(59,159,226, 1))"
                    }}
                  />
                </a>
                <div className="flex flex-col gap-1 lg:gap-3">
                  {/*<h1 class="text-base md:text-xl w-full text-center" style="font-weight:800;color:#58606B;">Amplify Your Imagination</h1>*/}
                  <p className="text-gray-600 w-full text-center font-light text-sm md:text-base pointer-events-auto">
                    <a href="mailto:create@create.ai">
                      Amplify Your Imagination. Create.AI is currently under development.
                      <br />
                      <br />
                      Contact us:&nbsp;
                      <b><a href="mailto:create@create.ai">create@create.ai</a></b>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative bottom-14 md:bottom-14 bg-createblue-50 rounded-b-2xl p-4 bg-opacity-75 max-w-[950px] mx-auto">
            <span className="text-center pb-2 text-white text-sm">
              © 2025 Create.AI, Inc.
            </span>
          </div>
        </div>
        <img
          src="/assets/images/shapes_1.png"
          className="absolute left-0 bottom-[-40px] -z-30 pointer-events-none"
          alt=""
        />
        <img
          src="/assets/images/shapes_2.png"
          className="absolute right-0 top-0 -z-30 pointer-events-none"
          alt=""
        />
      </>

    </div>
  );
}

export default App;
